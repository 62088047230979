import * as React from 'react';

//import Layout from "../components/layout"

import * as style from './404.module.scss';

const NotFoundPage = () => (
  //<Layout>
  <div className={style.not_found}>
    <h1>We're sorry!</h1>
    <p>That address does not match any of our content pages.</p>
    <p>
      Please visit our homepage or use our menu to find what you are looking
      for.
    </p>
    <div className={style.bibleverse}>
      <p>
        Do not be anxious about anything, but in every situation, by prayer and
        petition, with thanksgiving, present your requests to God. And the peace
        of God, which transcends all understanding, will guard your hearts and
        your minds in Christ Jesus.
      </p>
      <p className={style.verse}>Philippians 4:6–7 (NIV)</p>
    </div>
  </div>
  //</Layout>
);

export default NotFoundPage;
